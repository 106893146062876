import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import ViewUI from 'view-design';

import request from '@/utils/request'
import logUrl from '@/utils/getLogUrl'

// import style
import 'video.js/dist/video-js.css'
import 'view-design/dist/styles/iview.css'

import Videojs from 'video.js'
Vue.prototype.$video = Videojs

import VueDragResize from 'vue-drag-resize'
Vue.component('vue-drag-resize', VueDragResize)

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import GeminiScrollbar from 'vue-gemini-scrollbar'
Vue.use(GeminiScrollbar)

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import vueFilter from './plugins/filter'
Object.keys(vueFilter).forEach((key: string) => {
  Vue.filter(key, vueFilter[key])
})

Vue.config.productionTip = false
Vue.use(ViewUI)
// 全局路由守卫
router.beforeResolve((to, from, next) => {
  /* must call `next` */
  const { token, current } = store.state
  // log
  const url = logUrl.getUrl(to.path)
  if (url) {
    request.post(`/api/sys/log/save/${url}`)
  }
  // 访问登录页面
  if (to.path === '/login') {
    if (token && current) {
      next('/')
    } else {
      next()
    }
    return
  }
  // 访问非登录页
  if (!token) {
    return next('/login')
  } else if (current) {
    return next()
  }
  request.get('/api/sys/current')
  .then((data: any) => {
    const newData = data
    if (newData.areaId === '610703') {
      newData.areaId = '610721'
    }
    store.commit('setCurrent', newData)
    next()
  }).catch(() => {
    next('login')
  })
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
