

































import Vue from 'vue'
import request from '@/utils/request'
import config from '@/utils/config'
export default Vue.extend({
  data() {
      return {
        title: config.title,
        formData: {
          username: 'admin',
          password: '',
        },
        rules: {
          username: [
            { required: true, message: '用户名不能为空', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '密码不能为空', trigger: 'blur' },
          ],
        },
      };
    },
    mounted() {
      // this.init()
      // this.toLogin()
    },
    methods: {
      init() {
        const { token, current } = this.$store.state
        if (token && current) {
          this.$router.replace('/')
        }
      },
      toLogin() {
        const FormData: any = this.$refs.loginForm
        FormData.validate((valid: boolean) => {
          if (valid) {
            const params = this.formData
            request.post('/api/sys/auth/login', params)
            .then((token: string) => {
              return this.$store.commit('login', token)
            }).then(() => {
              this.$Message.success('登录成功!')
              this.$router.replace('/')
            }).catch((errorMsg: string) => {
              this.$Message.error(errorMsg)
            })
          }
        });
      },
    },
});
