


















import Vue from 'vue'
import config from '@/utils/config'

export default Vue.extend({
  data() {
    return {
      query: {}as any,
    }
  },
  created() {
    this.query = this.$route.query
    // console.log(this.query)
  },
  mounted() {
    const url = this.query.filePath
    console.log(url)
    this.init(url)
  },
  methods: {
    init(url: any) {
      let editorSdk: any = null
      const onAppReady = () => {
          console.log('编辑器加载完成');
      }
      const onDocumentReady = () => {
          console.log('文档已加载完成');
      }
      const onError = (event: any) => {
          // console.log("编辑器错误: code " + event.data.errorCode + ", 描述" + event.data.errorDescription);
      }
      const onRequestClose = () => {
          if (window.opener) {
              window.close();
              return;
          }
          editorSdk.destroyEditor();
      }
      const onWarning = (event: any) => {
          // console.log("编辑器警告: code " + event.data.warningCode + ", 描述" + event.data.warningDescription);
      }
      const cxoConfig = {
        document: {
          fileType: 'doc',
          key: this.query.key,
          permissions: {
            download: true,
            edit: false,
            print: false,
            review: false,
            copyoutenabled: false,
          },
          url,
        },
        documentType: 'text',
        editorConfig: {
          customization: {
            leftMenu: false,
            chat: false,
            toolbar: false,
            header: false,
            statusBar: true,
            zoom: 100,
          },
          mode: 'view',
        },
        events: {
          onAppReady,
          onDocumentReady,
          // "onDownloadAs": onDownloadAs,
          onError,
          onRequestClose,
          onWarning,
        },
        height: '100%',
        type: 'desktop',
        width: '100%',
      }
      // @ts-ignore
      editorSdk =  new CXO_API.CXEditor('CXO_Editor_SDK', cxoConfig)
    },
  },
})
