import moment from 'moment'
const vFilter: any = {
  /*
  时间戳转为YYYY-MM-DD HH:mm:ss
  格式化时间
   */
  formatDate(date: any) {
    if (!date) {
      return ''
    }
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  },
}
export default vFilter
