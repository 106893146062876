







































import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import warningImg from '@/utils/baseData/warningImg'
import screenfull from 'screenfull'
const barList = [
    {
        name: '监测预警',
        path: '/monitor',
        img: '/static/image/barIcon/monitor.png',
        imgActive: '/static/image/barIcon/monitor_a.png',
    },
    {
        name: '精细化预报',
        path: '/forecast',
        img: '/static/image/barIcon/forecast.png',
        imgActive: '/static/image/barIcon/forecast_a.png',
    },
    {
        name: '数据查询',
        path: '/data_query',
        img: '/static/image/barIcon/dataquery.png',
        imgActive: '/static/image/barIcon/dataquery_a.png',
    },
    {
        name: '系统设置',
        path: '/sys_manage',
        img: '/static/image/barIcon/sysmanage.png',
        imgActive: '/static/image/barIcon/sysmanage_a.png',
    },
]
export default Vue.extend({
    data() {
        return {
            title: config.title,
            current: null,
            barList,
            fullscreen: false,
        }
    },
    mounted() {
        const {current} = this.$store.state
        this.current = current
        this.refWebToken()
    },
    methods: {
        refWebToken() {
            request.get('/api/sys/auth/refresh', null)
            .then((token: string) => this.$store.commit('login', token))
            .catch(() => this.logout())
        },
        async logout() {
            await this.$store.commit('logout')
            this.$router.push('/login')
        },
        handleFullScreen() {
            if (screenfull.isEnabled) {
                screenfull.toggle()
            }
            window.onresize = () => {
                this.fullscreen = screenfull.isEnabled && screenfull.isFullscreen
            }
        },
    },
})
