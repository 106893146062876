import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/login.vue';
import Layout from './views/Layout.vue';
import docView from './views/monitor/docView.vue'

Vue.use(Router);

const releaseRouters = [
  {
    name: 'callInfo',
    path: 'callInfo',
    component: (resolve: any) => require(['@/views/service/release/dataComponents/call_info'], resolve),
  }, {
    path: 'callWarn',
    component: (resolve: any) => require(['@/views/service/release/dataComponents/call_warn'], resolve),
  }, {
    path: 'callRecond',
    component: (resolve: any) => require(['@/views/service/release/dataComponents/call_recond'], resolve),
  }, {
    path: 'callOutbound',
    component: (resolve: any) => require(['@/views/service/release/dataComponents/call_outbound'], resolve),
  },
]
const sysManageRouters = [
  {
    path: 'area',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_area/sys_area_list'], resolve),
  }, {
    path: 'office',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_office/sys_office_list'], resolve),
  }, {
    path: 'office/add_office',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_office/sys_office_add'], resolve),
  }, {
    path: 'office/edit_office/:id',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_office/sys_office_edit'], resolve),
  }, {
    path: 'user',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_user/sys_user_list'], resolve),
  }, {
    path: 'user/add_user',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_user/sys_user_add'], resolve),
  }, {
    path: 'user/edit_user/:id',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_user/sys_user_edit'], resolve),
  }, {
    path: 'user/reset_pwd/:id',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_user/sys_user_reset_pwd'], resolve),
  }, {
    path: 'user/join_role/:id',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_user/sys_user_join_role'], resolve),
  }, {
    path: 'role',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_role/sys_role_list'], resolve),
  }, {
    path: 'role/add_role',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_role/sys_role_add'], resolve),
  }, {
    path: 'role/edit_role/:id',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_role/sys_role_edit'], resolve),
  }, {
    path: 'role/join_res/:id',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_role/sys_role_join_res'], resolve),
  }, {
    path: 'res',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_res/sys_res_list'], resolve),
  }, {
    path: 'res/res_join_role/:id',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_res/sys_res_join_role'], resolve),
  }, {
    path: 'operate_log',
    component: (resolve: any) => require(['@/views/sys_manage/sys_manage/sys_log/sys_log_list'], resolve),
  },
]
const thresholdManageRouters = [
  {
    path: 'publishRole',
    component: (resolve: any) => require(['@/views/sys_manage/threshold_manage/role/role_list'],
     resolve),
  }, {
    path: 'publishRole/add_publishRole',
    component: (resolve: any) => require(['@/views/sys_manage/threshold_manage/role/role_add'],
     resolve),
  }, {
    path: 'publishRole/edit_publishRole/:id',
    component: (resolve: any) => require(['@/views/sys_manage/threshold_manage/role/role_edit'],
     resolve),
  }, {
    path: 'publishMember',
    component: (resolve: any) => require(['@/views/sys_manage/threshold_manage/member/member_list'],
     resolve),
  }, {
    path: 'publishMember/add_publishMember',
    component: (resolve: any) => require(['@/views/sys_manage/threshold_manage/member/member_add'],
     resolve),
  }, {
    path: 'publishMember/edit_publishMember/:id',
    component: (resolve: any) => require(['@/views/sys_manage/threshold_manage/member/member_edit'],
     resolve),
  }, {
    path: 'threshold',
    component: (resolve: any) =>
    require(['@/views/sys_manage/threshold_manage/threshold_setting/threshold_setting_list'], resolve),
  }, {
    path: 'threshold/add_threshold',
    component: (resolve: any) =>
    require(['@/views/sys_manage/threshold_manage/threshold_setting/threshold_setting_add'], resolve),
  }, {
    path: 'threshold/edit_threshold/:id',
    component: (resolve: any) =>
    require(['@/views/sys_manage/threshold_manage/threshold_setting/threshold_setting_edit'], resolve),
  },
]
const sysInfoRouters = [
  {
    path: 'oilInfo',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_oilInfo/sys_oilInfo_list'], resolve),
  }, {
    path: 'oilInfo/add_oilInfo',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_oilInfo/sys_oilInfo_add'], resolve),
  }, {
    path: 'oilInfo/edit_oilInfo/:id',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_oilInfo/sys_oilInfo_edit'], resolve),
  }, {
    path: 'eLectricInfo',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_eLectricInfo/sys_eLectricInfo_list'], resolve),
  }, {
    path: 'eLectricInfo/add_eLectricInfo',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_eLectricInfo/sys_eLectricInfo_add'], resolve),
  }, {
    path: 'eLectricInfo/edit_eLectricInfo/:id',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_eLectricInfo/sys_eLectricInfo_edit'], resolve),
  }, {
    path: 'warnRange',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_warnRange/sys_warnRange_list'], resolve),
  }, {
    path: 'warnRange/add_warnRange',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_warnRange/sys_warnRange_add'], resolve),
  }, {
    path: 'warnRange/edit_warnRange/:id',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_warnRange/sys_warnRange_edit'], resolve),
  }, {
    path: 'intensity',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_intensity/sys_intensity_list'], resolve),
  }, {
    path: 'intensity/add_intensity',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_intensity/sys_intensity_add'], resolve),
  }, {
    path: 'intensity/edit_intensity/:id',
    component: (resolve: any) =>
    require(['@/views/sys_manage/sys_info/sys_intensity/sys_intensity_edit'], resolve),
  },
]
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/docView',
      component: docView,
    },
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '/',
          redirect: 'monitor',
        },
        {
          path: 'monitor',
          component: (resolve) => require(['@/views/monitor/aPage'], resolve),
          children: [
            { path: '/', redirect: 'main'},
            {
              path: 'main',
              component: (resolve) => require(['@/views/monitor/monitor'], resolve),
            },
          ],
        },
        {
          path: 'forecast',
          component: (resolve) => require(['@/views/forecast/aPage'], resolve),
          children: [
            { path: '/', redirect: 'main'},
            {
              path: 'main',
              component: (resolve) => require(['@/views/forecast/forecast'], resolve),
            },
          ],
        },
        {
          path: 'data_query',
          component: (resolve) => require(['@/views/dataquery/aPage'], resolve),
          children: [
            { path: '/', redirect: 'main'},
            {
              path: 'main',
              component: (resolve) => require(['@/views/dataquery/dataquery'], resolve),
            // }, {
            //   path: 'release',
            //   component: (resolve) => require(['@/views/service/release/release'], resolve),
            //   children: [
            //     { path: '/', redirect: 'callInfo'},
            //     ...releaseRouters,
            //   ],
            // }, {
            //   path: 'callresponse',
            //   component: (resolve) => require(['@/views/service/callresponse/callresponse'], resolve),
            },
          ],
        },
        {
          path: 'sys_manage',
          component: (resolve) => require(['@/views/sys_manage/aPage'], resolve),
          children: [
            { path: '/', redirect: 'area'},
            ...sysManageRouters,
            ...thresholdManageRouters,
            ...sysInfoRouters,
          ],
        },
      ],
    },
  ],
});
