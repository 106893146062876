const getBaseUrl = () => {
  const href = window.location.href
  const path = window.location.pathname
  const url = href.split(path)[0]
  const str = href.split(':').slice(0, 2).join(':')
  let urlStr = ''
  if (str === 'http://localhost') {
    urlStr = 'http://124.70.32.82:8729'
  } else {
    urlStr = url
  }
  return urlStr
}
const baseUrl = getBaseUrl()
export default {
  title: '延长石油西安输油末站雷电监测预警系统',
  defaultStationObj: {
    adminCode: '610115',
    stationName: '临潼区',
    stationId: '57128',
    lat: 34.478,
    lon: 109.28,
  },
  siteCode: 'XiAn',
  baseUrl,
}
