import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

const instance = axios.create({ baseURL: '' })

/* 请求拦截器 */
instance.interceptors.request.use((config) => {
  config.headers.common['content-type'] = 'application/json;charset=UTF-8'
  const { token } = store.state
  if (token && token !== 'undefined') {
    config.headers.common.Authorization = token
  }
  return config
}, (error) => Promise.reject(error))

const codeMessage: any = {
  200: '服务器成功返回请求的数据',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据,的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
};

const myCatch = (error: any) => {
  const { status, data = '' } = error.response;
  let msg: string = '';
  if (data) {
    msg = data
  } else if (codeMessage[status]) {
    msg = codeMessage[status]
  }
  if (msg) {
    return Promise.reject(msg)
  }
  return Promise.reject('网络访问出错')
};
const get = (url: string, params?: any) => instance.get(url, { params }).then((res) => res.data).catch(myCatch)

const put = (url: string, params?: any) => instance.put(url, params).then((res) => res.data).catch(myCatch)

const post = (url: string, params?: any) => instance.post(url, params).then((res) => res.data).catch(myCatch)

const del = (url: string) => instance.delete(url).then((res) => res.data).catch(myCatch)

// 挂载到全局vue实例上，在页面中可以使用this.$request调用request实例下相应方法
export default { post, get, put, del }
