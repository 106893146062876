const getUrl = (param: string) => {
    let url = ''
    // if (param === '/sys_manage/area') {
    //     url = 'sys_manage_area'
    if (param === '/sys_manage/office') {
        url = 'sys_manage_office'
    } else if (param === '/sys_manage/office') {
        url = 'sys_manage_office'
    } else if (param === '/sys_manage/user') {
        url = 'sys_manage_user'
    } else if (param === '/sys_manage/role') {
        url = 'sys_manage_role'
    } else if (param === '/sys_manage/res') {
        url = 'sys_manage_res'
    } else if (param === '/sys_manage/template') {
        url = 'sys_manage_template'
    } else if (param === '/sys_manage/publishRole') {
        url = 'sys_manage_publish_role'
    } else if (param === '/sys_manage/publishMember') {
        url = 'sys_manage_publish_member'
    } else if (param === '/sys_manage/threshold') {
        url = 'sys_manage_threshold'
    } else if (param === '/service/dataquery') {
        url = 'service_dataquery'
    } else if (param === '/service/product/list') {
        url = 'service_product_list'
    } else if (param.indexOf('/service/production/list') > -1) {
        url = 'service_production'
    } else if (param.indexOf('/service/release') > -1) {
        url = 'service_release'
    } else if (param.indexOf('/monitor/main') > -1) {
        url = 'monitor_main'
    } else if (param.indexOf('/gispic/main') > -1) {
        url = 'gispic_main'
    } else if (param.indexOf('/forecast/main') > -1) {
        url = 'forecast_main'
    }
    return url
}
export default { getUrl }
