import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'

const STORAGEPREFIX = 'lintong.'
const formatKey = (key: string) => `${STORAGEPREFIX}${key}`

let token = ''
try {
  token = localStorage.getItem(formatKey('token')) || ''
} catch (e) {
  console.error(e)
}
Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    token,
    current: null,
    warnNum: 0,
    callIndex: 0,
  },
  mutations: {
    login(state: RootState, tokenTmp: string) {
      state.token = tokenTmp
      state.current = null
      localStorage.setItem(formatKey('token'), tokenTmp)
    },
    logout(state: RootState) {
      state.token = null
      state.current = null
      state.warnNum = 0
      localStorage.removeItem(formatKey('token'))
    },
    setCurrent(state: RootState, current) {
      state.current = current
    },
    setWarnNum(state: RootState, warnNum) {
      state.warnNum = warnNum
    },
    setCallIndex(state: RootState, callIndex) {
      state.callIndex = callIndex
    },
  },
};
if (!Vue.prototype.$store) {
  Vue.prototype.$store = new Vuex.Store<RootState>(store)
}
export default Vue.prototype.$store
